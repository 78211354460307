<template>
    <v-container fluid class="px-8">
        <v-card class="d-flex flex-wrap mb-6" flat>
            <div class="topic-type-title">
                我的课程包
            </div>
            <v-card
                class="pa-4 me-6 mb-6"
                width="232"
                height="314"
                @click.stop="openDlg()"
            >
                <v-card
                    class="px-4 pt-4 me-6 mb-6 d-flex align-center add-topic"
                    color="orange"
                    width="100%"
                    height="100%"
                >
                    <v-card-text>
                        <div class="text-h4 font-weight-bold white--text mb-3">
                            创建课程包
                        </div>
                        <v-icon large color="white">
                            mdi-plus-circle-outline
                        </v-icon>
                    </v-card-text>
                </v-card>
            </v-card>

            <v-card class="px-4 pt-4 me-6 mb-6"
                v-for="(data, index) in data4MyTopic"
                :key="index"
                @click.stop="goto(data.topic_id)"
            >
                <div class="img-wrapper">
                    <v-img
                        :src="getImgUrl(data.cover, defaultImg4Topic)"
                        height="220"
                        width="200"
                        class="grey darken-4 img-scale"
                    ></v-img>
                </div>
                <v-card-text class="text-wrapper">
                    <div class="text-h4 font-weight-bold ellipsis">{{ data.name }}</div>
                    <div class="text-subtitle-1 ellipsis">{{ data.desc }}</div>
                </v-card-text>
            </v-card>
        </v-card>

        <v-card class="d-flex flex-wrap mb-6" flat>
            <div class="topic-type-title">
                课程包中心
            </div>
            <v-card class="px-4 pt-4 me-6 mb-6 topic-card"
                v-for="(data, index) in data4StoreCourseTopic"
                :key="index"
                @click.stop="gotoPreview(data.topic_id, data.is_my_topic)"
            >
                <div class="img-wrapper">
                    <v-img
                        :src="getImgUrl(data.cover, defaultImg4Topic)"
                        height="220"
                        width="200"
                        class="grey darken-4 img-scale"
                    ></v-img>
                </div>
                <v-card-text class="text-wrapper">
                    <div class="text-h4 font-weight-bold ellipsis">{{ data.name }}</div>
                    <div class="text-subtitle-1 ellipsis">{{ data.desc }}</div>
                </v-card-text>

                <div class="flag active" v-if="data.is_my_topic">已购买</div>
                <div class="flag" v-else>未购买</div>
            </v-card>
        </v-card>

        <EditTopicDlg ref="editTopicDlg" @submit="submitTopic" />

    </v-container>
</template>

<script>
import EditTopicDlg from "@/views/components/dialog/EditTopicDlg";

import { getAdminTopicStoreList, getAdminTopicList, editAdminTopic } from "@/api/admin";
import { getImgUrl } from "@/utils/util";

export default {
    name: "Topic",

    data() {
        return {
            defaultImg4Topic: require('@/assets/images/admin/default-cover.jpeg'),

            data4StoreCourseTopic: [],
            data4StoreBookTopic: [],

            data4MyTopic: [],
        };
    },

    mounted() {
    },
    activated() {
        this.loadData();
    },

    methods: {
        getImgUrl,
        async loadData() {
            getAdminTopicList('PRIVATE').then((response) => {
                this.data4MyTopic = response.data;
                
            }).catch(function (err) {
                console.log(err);
            });

            getAdminTopicStoreList().then((response) => {
                this.data4StoreCourseTopic = response.data.courseTopics;
                this.data4StoreBookTopic = response.data.bookTopics;

            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            this.$router.push({ path: "/course-mgr/topic-list/toic", query: { id: id }});
        },
        gotoPreview(id, isMyTopic) {
            if (isMyTopic) {
                this.$router.push({ path: "/course-mgr/topic-list/toic", query: { id: id } });
            } else {
                this.$router.push({ path: "/course-mgr/topic-list/topic-preview", query: { id: id } });
            }
        },
        openDlg() {
            this.$refs.editTopicDlg.openDlg();
        },
        submitTopic(result) {
            editAdminTopic({
                topicId: '',
                name: result.name, 
                desc: result.desc, 
                cover: result.cover,
                index: result.index
            }).then((response) => {
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建课程包成功"});

                    // 为新建课程包补充初始数据
                    let topic = response.data

                    // TODO: 暂时先放在最后面
                    // 上面的result.index暂未调整，后续优化
                    result.topic_id = topic.topic_id
                    result.index = this.data4MyTopic.length
                    result.is_online = 0
                    this.data4MyTopic.push(result)

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建课程包失败"});
                }

            }).catch(function (err) {
                console.log(err);
            });
        },
    },
    components: {
        EditTopicDlg,
    },
};
</script>

<style lang="scss" scoped>
.btn-topic-group {
    margin-bottom: 20px;
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.topic-type-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 8px solid #3F51B5;
    border-radius: 0 !important;
    padding-left: 12px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #444;
}
.topic-empty {
    width: 100%;
    height: 314px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        color: #333;
        letter-spacing: 1px;
    }
    .empty-goto {
        letter-spacing: 1px;
        margin-right: 0 !important;
        margin-bottom: 30px !important;
    }
}

.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
.add-topic {
    cursor: pointer;
}
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.img-wrapper {
    height: 220px;
    width: 200px;
    overflow: hidden;
    .img-scale {
        &:hover {
            transform: scale(1.2); // 放大1.2倍
            transition: all .5s; // 设置加载时间
        }
    }
}
.text-wrapper {
    width: 200px;
    padding: 16px 0;
}
.btn-del-topic {
    position: absolute;
    top: 15px;
    right: 10px;
    &:hover {
        background-color: #eee !important;
    }
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.topic-card {
    position: relative;
    .flag {
        background-color: #9E9E9E;
        position: absolute;
        top: 30px;
        right: 0;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 3px 25px;
        border-radius: 0 !important;
        &.active {
            background-color: #E53935;
        }
    }
}
</style>
